<template lang="pug">
    .main-wrapper.orcamentos-validados-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Orçamentos Pagos</b>

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='false')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-4
                    label.form-label Nome do paciente:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_paciente'
                            @input='(value) => { if (!value) applyFilters() }' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Nome do especialista:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.nm_especialista'
                            @input='(value) => { if (!value) applyFilters() }' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Procedimento:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_procedimento'
                            @input='(value) => { if (!value) applyFilters() }' )
                        Button(icon='jam jam-search' @click='applyFilters()')

        Panel.datatable-panel(header='Lista de orçamentos')
            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")
            div(style="overflow-x: scroll")
                DataTable.datatable(:value="list")
                    Column(headerStyle='width: 4em;' field='id' header='Cód.' bodyStyle='text-align:center')
                    Column(headerStyle='width: 17%;' field='nm_paciente' header='Paciente')
                    Column(headerStyle='width: 17%;' field='ds_descricao' header='Cirurgia')
                    Column(headerStyle='width: 17%;' header='Especialista(s)' field='nm_especialistas')
                    Column(headerStyle='width: 10%;' header='Situação' bodyStyle='padding:0;')
                        template(#body='props')
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 3"
                                :class="{s3: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                            .cell(v-if="props.data.cd_orcamento_situacao.id === 4"
                                :class="{s4: true}") {{ props.data.cd_orcamento_situacao.ds_situacao }}
                    Column(headerStyle='width: 7em;' header='Dt. Pagamento' bodyStyle='text-align:center')
                        template(#body='props')
                            span(v-tooltip.top="`Usuário: ${props.data.nm_usuario_validacao}`") {{ props.data.dt_validacao_f }}
                    Column(headerStyle='width: 7em;' header='Dt. Execução' bodyStyle='text-align:center')
                        template(#body='props')
                            span(v-tooltip.top="`Usuário: ${props.data.nm_usuario_execucao || 'Não encontrado!'}`") {{ props.data.dt_execucao_f }}
                    Column(headerStyle='width: 5em;' header='Tipo' bodyStyle='text-align:center')
                        template(#body='props')
                            span {{ props.data.ie_conta_aberto ? 'Conta aberta' : 'Pacote' }}
                    Column(headerStyle='width: 10%;' header='Valor total' bodyStyle='text-align:right')
                        template(#body='props')
                            span {{ $root.formatPrice(props.data.nr_valor_total) }}
                    Column(headerStyle='width: 10%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Visualizar'"
                                    icon="pi pi-external-link"
                                    @click="$router.push(`/orcamentos-clinica/visualizar/${ props.data.id }`)"
                                    )
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Imprimir guia'"
                                    icon="jam jam-document"
                                    @click="imprimirGuia(props.data)"
                                    )

                    template(#empty)
                        ProgressBar.mt-2.mb-2(v-if='waiting' mode="indeterminate")
                        .ta-center(v-else) Nenhum registro encontrado.

            Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage")
        BlockUI(:blocked="waitingImprimirGuia" :fullScreen="true")

</template>

<style lang="scss">
    .orcamentos-validados-listar {
        .datatable {
            .cell {
                padding: 16px 0;
                text-align: center;
                &.s3 { background-color: #ccfffc; }
                &.s4 { background-color: #d5d7ff; }
            }
            min-width: 1100px;
        }
    }
</style>

<script>
    import BlockUI from 'primevue/blockui'
    import Button from 'primevue/button'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import Dialog from 'primevue/dialog'
    import InputText from 'primevue/inputtext'
    import Panel from 'primevue/panel'
    import Paginator from 'primevue/paginator'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Tooltip from 'primevue/tooltip'

    import { Caixas, Orcamento } from '../../middleware'
    import moment from 'moment'
    import wsConfigs from './../../middleware/configs'

    export default {
        components: { BlockUI, Button, Column, DataTable, Dialog, InputText,
            Panel, Paginator, ProgressBar, ProgressSpinner, Tooltip },
        directives: { tooltip: Tooltip },
        created () {
            this.applyFilters()
        },
        data () {
            return {
                waiting: false,
                waitingImprimirGuia: false,
                list: [],
                filters: {
                    nm_paciente: null,
                    nm_especialista: null,
                    ds_procedimento: null
                },
                paginator: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                }
            }
        },
        methods: {
            getList (params) {
                this.waiting = true
                this.list = []
                return Orcamento.findAllValid(params).then(response => {
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                        this.list.forEach(item => {
                            if (item.dt_validacao) {
                                item.dt_validacao_f = moment(item.dt_validacao).format('DD/MM/YYYY')
                            }
                            if (item.dt_execucao) {
                                item.dt_execucao_f = moment(item.dt_execucao).format('DD/MM/YYYY')
                            }

                            item.nm_especialistas = ''
                            let mem = {}
                            item.cd_orcamento_cirurgia_procedimento_estabelecimento.forEach(i => {
                                if (i.cd_especialista)
                                    if (! mem[i.cd_especialista.id]) {
                                        mem[i.cd_especialista.id] = 1
                                        if (item.nm_especialistas.length) item.nm_especialistas += ' / '
                                        item.nm_especialistas += i.cd_especialista.nm_especialista
                                    }
                            })
                            item.nm_paciente = item.cd_paciente?.nm_pessoa_fisica || item.nm_paciente
                        })
                    }
                    this.waiting = false
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]  })
                this.getList(params)
            },
            onPage (ev) {
                if (this.paginator.page !== ev.page + 1) {
                    this.paginator.page = ev.page + 1
                    this.applyFilters()
                }
            },
            imprimirGuia(orcamento) {
                this.waitingImprimirGuia = true
                Caixas.imprimirGuiasLote(orcamento.cd_transacao_financeira).then(response => {
                    this.waitingImprimirGuia = false
                    if (response.status === 200) {
                        if (response.data.detail) {
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        } else {
                            let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                            let fileURL = URL.createObjectURL(blob)
                            let a = window.open(fileURL)
                            a.print()
                        }
                    }
                })
            }
        }
    }
</script>
